<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-buttons slot="start">
            <ion-back-button defaultHref="/home/packages"></ion-back-button>
          </ion-buttons>
        </ion-buttons>

        <ion-title>
          {{ id }}
        </ion-title>

        <ion-buttons slot="end">
          <ion-buttons slot="start">
            <ion-button @click="openNotificationSettings">
              <ion-icon :icon="ellipsisVerticalOutline" />
            </ion-button>
          </ion-buttons>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <!-- Package info -->
      <ion-card>
        <ion-card-header class="avatar-header">
          <ion-avatar>
            <img :src="packageInfo?.logo" />
          </ion-avatar>
          <ion-card-subtitle class="text-right">
            last updated: {{ TimePassedPipe(packageInfo?.lastUpdated.toDate()) }}
          </ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-list v-if="packageInfo">
            <ion-item lines="none">
              <ion-label>
                <p> Latest version </p>
                <h3> {{ packageInfo?.latestVersion }} </h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-label>
                <p> Repository </p>
                <h3>
                  <a :href="'https://github.com/' + packageInfo?.repository" target="_blank">
                    {{ packageInfo?.repository }}
                    <ion-icon :icon="openOutline" />
                  </a>
                </h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-label>
                <p> Registry </p>
                <h3> {{ packageInfo?.registry }} </h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-label>
                <p> Languages </p>
                <ion-row>
                  <ion-col size="6" v-for="language in mapLanguagesObjectToPercentageArray(packageInfo?.github.languages)" :key="language.name">
                    &bull; {{language?.name}} - {{language?.lines}}
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-label>
                <p> Contributors </p>
                <div class="contributors-wrapper">
                  <div v-for="contributor in sortContributorsByCommitsAcs(packageInfo?.github.contributors)"
                       class="contributor" :key="contributor.name">
                     <a :href="contributor.author.url" target="_blank">
                      <img :src="contributor.author.avatar" />
                     </a>
                  </div>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>

        </ion-card-content>
      </ion-card>

      <!-- Release list -->
      <ion-card v-if="!selectedRelease">
        <!-- Release counter -->
        <ion-card-header>
          <ion-card-subtitle class="text-right">
            <span v-if="packageReleases"> releases: {{ packageInfo?.releaseCount }} </span>
            <ion-skeleton-text v-else animated style="width: 80px; height: 16px; margin: auto 0 auto auto;"/>
          </ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <!-- Release list -->
          <ion-list v-if="packageReleases">
            <ion-item v-for="release in packageReleases" :key="release.uid"
                      lines="none" detail="true"
                      @click="openRelease(release)">
              <ion-label>
                <div class="version-label">
                  <div>
                    {{ release.version }}
                  </div>
                  <ion-chip v-if="release.version === packageInfo.latestVersion" color="primary" class="ion-list-chip">
                    <ion-label> latest </ion-label>
                  </ion-chip>
                  <ion-chip v-if="release.isPrerelease" color="secondary" class="ion-list-chip">
                    <ion-label> pre-release </ion-label>
                  </ion-chip>
                </div>
                <p>
                  by {{ release.user }} &bull;
                  {{ TimePassedPipe(release.timestamp.toDate()) }}
                </p>
              </ion-label>
            </ion-item>

            <ion-button v-if="packageReleases.length < packageInfo.releaseCount"
                        color="primary" fill="outline" class="item-padding">
              Load {{ packageInfo.releaseCount - packageReleases.length }} more
            </ion-button>
          </ion-list>

          <!-- Release loading skeleton -->
          <ion-list v-else>
            <ion-item v-for="x in new Array(5)" :key="x" lines="none">
              <ion-label>
                <h2>
                  <ion-skeleton-text animated style="width: 80px; height: 24px;"/>
                </h2>
                <p>
                  <ion-skeleton-text animated style="width: 110px; height: 14px; display: inline-block; margin-right: 10px"/>
                  <ion-skeleton-text animated style="width: 80px; height: 14px; display: inline-block;"/>
                </p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-card v-else>
        <!-- Release counter -->
        <ion-card-header>
          <ion-card-subtitle>
            <ion-button @click="closeRelease()"></ion-button>
          </ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          Selected Release {{selectedRelease?.version}}
        </ion-card-content>
      </ion-card>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" src="./package-info.ts" />
<style scoped src="./package-info.css" />
