
import { defineComponent, onBeforeMount, ref } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonSkeletonText,
  IonAvatar,
  IonChip,
  IonRow,
  IonCol,
  popoverController,
} from '@ionic/vue';

import {
  openOutline,
  ellipsisVerticalOutline,
} from 'ionicons/icons';

import firebase from 'firebase';
import NotificationSettings from '@/components/notification-settings/notification-settings.vue'
import { useRoute, useRouter } from 'vue-router';
import { TimePassedPipe } from '@/pipes/time-passed.pipe';
import { Release, Releases, Package, Contributors } from '@/types/firestore-collections.types';

export default defineComponent({
  name: 'PackageTypeList',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonButton,
    IonSkeletonText,
    IonAvatar,
    IonChip,
    IonRow,
    IonCol,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const firestore = firebase.firestore();

    const id = ref();
    const packageInfo = ref<Package>();
    const packageReleases = ref<Releases>();
    const packageLanguages = ref();
    const selectedRelease = ref<Release>();

    const loading = ref(true);
    const show = ref(true);


    const loadPackageInfo = async (id: string): Promise<void> => {
      const packagesSnapshot = await firestore
        .collection('package')
        .doc(id)
        .get();

      packageInfo.value = packagesSnapshot.data() as Package;
      console.log('Package info', packageInfo.value);
    };

    const loadPackageReleases = async (id: string): Promise<void> => {
      const releasesSnapshot = await firestore
        .collection('release')
        .orderBy('timestamp', 'desc')
        .where('package', '==', id)
        .limit(5)
        .get();

      const _releases: Releases = [];
      releasesSnapshot.forEach(_release => _releases.push({uid: _release.id, ..._release.data() as Release}));
      packageReleases.value = _releases;
    };

    const loadSelectedRelease = async (packageId: string, version: string): Promise<void> => {
      const releaseSnapshot = await firestore
        .collection('release')
        .doc(`${packageId}_${version}`)
        .get();

      console.log('Loaded release from url', releaseSnapshot);
      selectedRelease.value = {uid: releaseSnapshot.id, ...releaseSnapshot.data() as Release}
    };

    const openNotificationSettings = async (event: Event): Promise<void> => {
      const popover = await popoverController
        .create({
          component: NotificationSettings,
          event,
          translucent: true
        })
      await popover.present();
    }

    const openRelease = async (release: Release): Promise<void> => {
      await loadSelectedRelease(id.value, release.version);
      history.pushState(
        {},
        '',
        `/home/package/${id.value}/${release.version}`
      );
    }
    const closeRelease = (): void => {
      selectedRelease.value = undefined;
      history.pushState(
        {},
        '',
        `/home/package/${id.value}`
      );
    }

    const mapLanguagesObjectToPercentageArray = (languageObject: {}, maxLanguages = 5) => {
      // Map object to array
      const languages = Object.entries(languageObject)
        .map(([key, value]) => ({
          name: key,
          lines: value as number,
        }))
        .sort((a, b) =>
          a.lines < b.lines ? 1 :
          a.lines > b.lines ? -1 :
          0
        );

      // If there are more than `maxLanguages` languages sum them up to `other`
      if (languages.length > maxLanguages) {
        let otherCount = 0;
        for(let i = maxLanguages; i < languages.length; i++) {
          otherCount += languages[i].lines;
        }
        languages.splice(maxLanguages + 1, languages.length - maxLanguages);
        languages[maxLanguages].name = 'Other';
        languages[maxLanguages].lines = otherCount;
      }

      console.log('Languages', languages);
      return languages;
    }

    const sortContributorsByCommitsAcs = (contributors: Contributors): Contributors => {
      return contributors.sort((a, b) =>
        a.commits > b.commits ? 1 :
        a.commits < b.commits ? -1 :
        0
      );
    }

    onBeforeMount(async () => {
      // Base data
      id.value = route.params?.packageId as string;
      await loadPackageInfo(id.value);

      // Optional selected release
      const release = route.params?.releaseId as string;

      if(release) {
        await loadSelectedRelease(id.value, release)
      }

      await loadPackageReleases(id.value);

      loading.value = false;
    });


    return {
      loading,
      show,
      id,
      packageInfo,
      packageReleases,
      packageLanguages,
      selectedRelease,
      openOutline,
      ellipsisVerticalOutline,
      openNotificationSettings,
      openRelease,
      closeRelease,
      mapLanguagesObjectToPercentageArray,
      sortContributorsByCommitsAcs,
      TimePassedPipe,
    };
  },
});
