<template>
  <ion-content class="ion-padding">
    <div class="toggle-wrapper">
      <label class="label disabled"> Email </label>
      <ion-toggle color="primary" disabled="true">  </ion-toggle>
    </div>
    <div class="toggle-wrapper">
      <label class="label disabled"> Web </label>
      <ion-toggle color="primary" disabled="true">  </ion-toggle>
    </div>
  </ion-content>
</template>

<script>
import { IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationSettings',
  components: { IonContent }
});
</script>

<style scoped>
.toggle-wrapper {
  display: flex;
  align-items: center;
}
.label {
  flex: 1 1 auto;
}
.label.disabled {
  opacity: .4;
}
</style>
